import React, { Component } from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Container from '@components/Container'
import Hero from '@components/Hero'
import PageComponents from '@components/PageComponents'
import Footer from '@components/Footer'
import SEO from '@components/partials/SEO'
import { metaData } from '@fragments/meta'

export default class ServicesPage extends Component {
  constructor(props) {
    super(props)
    this.useFlyout = false
  }

  render() {
    const { data } = this.props
    const {
      wordpressPage: { yoast_meta: meta, ...page },
    } = data

    page.acf.hero.type = 'basic'
    if (page.acf.hero && !page.acf.hero.heading) {
      page.acf.hero.heading = page.title
    }

    return (
      <Container page={page}>
        <SEO {...meta} path={page.path} />
        {page.acf.hero && <Hero {...page.acf.hero} {...page} />}
        <main>
          {page.acf.content_page && (
            <article>
              <PageComponents components={page.acf.content_page} />
            </article>
          )}
        </main>
        <Footer />
      </Container>
    )
  }
}

ServicesPage.propTypes = {
  data: PropTypes.shape({
    allWordpressWpService: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

// TODO: Change order to weight on services and projects
export const pageQuery = graphql`
  {
    wordpressPage(slug: { eq: "services" }) {
      title
      path
      acf {
        hero {
          ...HeroFragment
        }
        content_page {
          __typename
          ...BannerComponentFragment
          ...CardsComponentFragment
          ...FeaturedProjectsComponentFragment
          ...FormComponentFragment
          ...ImageComponentFragment
          ...ImageBlocksComponentFragment
          ...ListBlockComponentFragment
          ...LogoGridComponentFragment
          ...SliderComponentFragment
          ...TeamComponentFragment
          ...TestimonialsComponentFragment
          ...TextComponentFragment
          ...TextWithImagesFragment
          ...TilesComponentFragment
        }
      }
      yoast_meta {
        ...metaData
      }
    }
  }
`
